import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import Model from './model/_base';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state:{
    logged: false,
    temporary: false, 
    accessToken: "",
    user: {},
    publicAuthCode: "",
    fromPortal: false,
    isMainMenuOpen:false,
    prPreview: {},
    sso: null,
    initiateForm: {},

  },
  mutations: {
    twoFactorValidated(state, {accessToken, cb}){
      state.accessToken = accessToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      state.logged = true;
      cb();
    },

    twoFactorInvalid(state, { accessToken, user }) {
      state.accessToken = accessToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      state.user = user;
    },
    
    passwordSetup(state, { accessToken, user }){
      state.accessToken = accessToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      state.user = user;
      state.logged = false;
      state.temporary = false;
    },

    login(state, { accessToken, user }) {
      state.accessToken = accessToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      state.user = user;
      state.logged = user.status.accountCreated;
      state.user.modifyPasswordFlg = false;
      // Copy from commit: "DEV-2023-5345 : change password rule" in develop branch
      state.user.isPasswordExpired = false;
      state.temporary = !user.status.accountCreated;
    },

    singleSignOn(state, { accessToken, user, singleSignOnInfo }) {
      state.accessToken = accessToken;
      axios.defaults.headers.common['Authorization'] = state.accessToken;
      state.user = user;
      state.logged = user.status.accountCreated;
      state.temporary = !user.status.accountCreated;
      state.sso = singleSignOnInfo;
    },

    updateUserName(state, {data, cb}){
      if(state.user){
        state.user.name = data.name;
        state.user.organizationName = data.organizationName;
      }
      cb();
    },
    
    continueToDashboard(state, {roles, cb}){
      state.temporary = false;
      state.logged = true;
      state.publicAuthCode = "";
      state.fromPortal = false;
      state.user.roles = roles;
      cb();
    },

    logout(state) {
      if(process.env.VUE_APP_DEMO_MODE === 'staging'){
        if(state.user.companyDisplayCode === 'SA000072' ||
          state.user.companyDisplayCode === 'SA000073'){
          state.logged = false;
          state.temporary = false;
          state.j2Logged = false;
          state.user = {};
          state.sso = null;
          
          setTimeout(function(){
            document.location.href = process.env.VUE_APP_J2_LOGIN_JR_URL;
          }, 100);
          return;
        }
      }
      state.logged = false;
      state.temporary = false;
      state.j2Logged = false;
      state.user = {};
      state.sso = null;
    },
    j2Login(state) {
      state.j2Logged = true;
    },

    setPublicAuthCode(state, {authCode}){
      state.publicAuthCode = authCode;
      state.fromPortal = true;
    },

    setStatus(state, status){
      state.user.status = status;
    },

    setPrPreview(state, {form, cb}){
      state.prPreview = form;
      cb();
    },
    setSingleSignOn(state, sso) {
      state.sso = sso;
    },
    setInitiateForm(state, initiateForm) {
      state.initiateForm = initiateForm;
    },
    setRoles(state, roles){
      state.user.roles = roles;
    },
    setUserOrgSwitch(state, userOrgSwitch){
      state.user.userOrgSwitch = userOrgSwitch
    }

  },
  actions: {
    async redirectSingleSignOnLink(context, {ssoLink, isCurrentTab = true}) {

      // BUG2023-2362: add validate user before sso
      const res = await axios.get(Model.baseURL + "auth/validate-user-before-sso?userCode=" + context.state.user.code);
      if (res && res.data && !res.data.valid) {
        context.commit('logout');
        if (res.data.temporaryPassword) {
          window.location.replace('/password-reset/' + res.data.temporaryPassword);
        } else {
          window.location.replace('/login');
        }
        return;
      }

      if (!ssoLink) {
        return;
      }
      const updatedSSO = Object.assign(context.state.sso || {}, {
        isSyncData: true
      });
      context.commit("setSingleSignOn", updatedSSO);
      if (isCurrentTab) {
        window.location.replace(ssoLink);
      } else {
        window.open(ssoLink);
      }
    }
  },
  getters: {
    singleSignOnParam(state) {
      if (!state.logged) {
        return null;
      }
      const sso = state.sso || {};
      let redirectUrl = sso.redirectUrl;
      let token = sso.initToken;
      let systemName = sso.initSystemName;
      // handle invalid data (as if)
      if (!redirectUrl || redirectUrl === "" || !isNaN(redirectUrl)) {
        redirectUrl = window.location.origin + "/auth/singleSignOut";
      }
      if (!token || token === "" || !isNaN(token)) {
        token = state.user.jtiToken;
      }
      if (!systemName || systemName === "" || !isNaN(systemName)) {
        systemName = "Bizhiway";
      }
      return `Token=${token}&SystemName=${systemName}&RedirectUrl=${redirectUrl}`;
    }
  }

});